import { useMapsLibrary } from "@vis.gl/react-google-maps"
import { useState, useEffect, useRef } from "react"
import { isBrowser } from "./Browser"
import { Language } from "@sixty-six-north/i18n"
import logger from "./logger"

const DEBOUNCE_TIMEOUT = 300

export function usePlacesAutocomplete(
  request: google.maps.places.AutocompletionRequest
) {
  const [hasError, setHasError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])

  const placesLibrary = useMapsLibrary("places")

  const placesService = useRef<google.maps.places.PlacesService | undefined>(
    undefined
  )
  const placesAutocompleteService = useRef<
    google.maps.places.AutocompleteService | undefined
  >(undefined)

  const buildServices = () => {
    if (!placesLibrary) return
    try {
      placesAutocompleteService.current =
        new placesLibrary.AutocompleteService()
      placesService.current = new placesLibrary.PlacesService(
        document.createElement("div")
      )
    } catch (error) {
      setHasError(true)
      logger.error(error)
    }
  }

  useEffect(() => {
    buildServices()
  }, [placesLibrary])

  useEffect(() => {
    if (!isBrowser) return

    if (typeof placesAutocompleteService.current === "undefined") {
      buildServices()
    }

    const handleDebounce = setTimeout(async () => {
      try {
        if (!request.input) {
          return
        }

        if (placesAutocompleteService?.current) {
          setLoading(true)

          const results =
            await placesAutocompleteService.current.getPlacePredictions(request)

          if (results && results.predictions) {
            setPredictions(results.predictions)
          } else {
            setHasError(true)
          }

          setLoading(false)
        }
      } catch (error) {
        setHasError(true)
        logger.error(error)
      }
    }, DEBOUNCE_TIMEOUT)

    return () => {
      clearTimeout(handleDebounce)
    }
  }, [request.input])

  return {
    predictions,
    placesAutocompleteHasError: hasError,
    isPlacesAutocompleteLoading: loading,
    placesService: placesService.current
  }
}

export const getAddressValuesForGooglePlace = (
  place: google.maps.places.PlaceResult | null,
  lang: Language
) => {
  let streetName = ""
  let city = ""
  let postcode = ""
  let state = ""
  let region = ""
  let country = ""

  if (place && place.address_components) {
    for (const component of place.address_components) {
      const componentType = component.types[0]
      switch (componentType) {
        case "subpremise": {
          streetName =
            streetName.length > 0
              ? `${streetName} ${component.long_name}`
              : component.long_name
          break
        }
        case "street_number": {
          streetName =
            streetName.length > 0
              ? `${streetName} ${component.long_name}`
              : component.long_name
          break
        }
        case "route": {
          streetName =
            lang === Language.is
              ? `${component.long_name} ${streetName}`
              : `${streetName} ${component.long_name}`
          break
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`
          break
        }
        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`
          break
        }
        case "postal_code_prefix": {
          postcode = `${component.long_name}${postcode}`
          break
        }
        case "sublocality_level_1":
        case "postal_town":
        case "locality":
          city = component.long_name
          break
        case "administrative_area_level_1": {
          const name = component.short_name
          state = name
          region = name
          break
        }
        case "country":
          country = component.short_name
          break
      }
    }
  }

  return {
    streetName,
    postalCode: postcode,
    country,
    city,
    state,
    region
  }
}
