//jsxhook
import {
  Column,
  FontWeight,
  Label2,
  LinkVariants,
  PhoneInput,
  Select,
  TextInput,
  InfoCard
} from "@sixty-six-north/ui-system"
import { Field, useField, useFormikContext } from "formik"
import React, { useEffect, useRef, useState, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Box, Grid } from "theme-ui"
import { CountryWithCodeI, defaultSupportedCountries } from "../../i18n/Region"
import { useSupportedCountries } from "../../i18n/SupportedCountriesProvider"
import { useAddressReferenceData } from "./AddressReferenceDataHooks"
import { GooglePlacesSearchDropdown } from "./GooglePlacesSearchDropdown"

export interface AddressOptions {
  showEnterAddress: boolean
  showPhoneField?: boolean
}

export const AddressForm: React.FC<AddressOptions> = ({
  showEnterAddress,
  showPhoneField = true
}) => {
  const { t } = useTranslation("checkout")

  const supportedCountries = useSupportedCountries()
  const { values, setFieldValue } = useFormikContext<{
    streetName: string
    postalCode: string
    city: string
    country: string
    state: string
    region: string
  }>()
  const { state, country, region } = values
  const [showAddress, setShowAddress] = useState(false)
  const vars = useRef({
    placeSelected: false
  })
  const { current: v } = vars

  useEffect(() => {
    setShowAddress(showEnterAddress)
  }, [showEnterAddress])

  useEffect(() => {
    if (state && country !== "US") {
      setFieldValue("state", "", false)
    }
    if (region && country !== "CA") {
      setFieldValue("region", "", false)
    }
  }, [country, state, region, setFieldValue])

  let postcode = <Postcode />
  switch (country) {
    case "US":
      postcode = <Zipcode />
      break
    case "IS":
      postcode = <IcelandicPostcode />
      break
  }

  return (
    <Column sx={{ width: "100%" }}>
      <Grid columns={2} gap={8}>
        <FirstName />
        <LastName />
      </Grid>

      <Country
        supportedCountries={supportedCountries}
        id="search-country"
        fieldName="searchCountry"
      />
      <Wrapper style={{ position: "relative" }}>
        <GooglePlacesSearchDropdown
          onPlaceSelectedStart={() => {
            v.placeSelected = true
            setShowAddress(false)
          }}
          onPlaceSelectedEnd={() => setShowAddress(true)}
        />
      </Wrapper>

      {showPhoneField && (
        <>
          <PhoneNumber />
        </>
      )}

      {!showAddress && (
        <Box>
          <Label2>
            <button
              type="button"
              id="manual-address-link"
              onClick={() => setShowAddress(true)}
              sx={{
                variant: `links.${LinkVariants.standard}`,
                fontSize: "scale-1",
                cursor: "pointer",
                background: "none",
                border: "none",
                m: 0,
                p: 0
              }}
            >
              {t("enterAddressManually", {
                defaultValue: "Enter address manually"
              })}
            </button>
          </Label2>
        </Box>
      )}
      {showAddress && (
        <>
          <Box sx={{ mt: 12, mb: 4 }}>
            <Label2
              sx={{
                fontWeight: FontWeight.textBold
              }}
            >
              {t(v.placeSelected ? "address" : "enterAddress", {
                defaultValue: "Enter address"
              })}
            </Label2>
          </Box>
          <AddressLineOne />
          <AddressLineTwo />
          <City />
          {country === "US" && <State />}
          {country === "CA" && <Province />}
          {postcode}
          {country === "US" && (
            <Wrapper>
              <InfoCard
                title={"U.S. Customs"}
                message={
                  "Due to recent changes in U.S. import tariffs, duties and taxes are not included in your purchase. These will be collected by the carrier upon delivery."
                }
                theme={"light"}
              ></InfoCard>
            </Wrapper>
          )}
          <Country supportedCountries={supportedCountries} />
        </>
      )}
    </Column>
  )
}

export const Wrapper: React.FC<{
  children?: ReactNode
  style?: Record<string, unknown>
}> = ({ children, style = {} }) => {
  return <Box sx={{ marginBottom: 8, ...style }}>{children}</Box>
}

const FirstName: React.FC = ({}) => {
  const [field, meta, helpers] = useField("firstName")
  const { t } = useTranslation("checkout")

  return (
    <Field
      id="first-name"
      as={TextInput}
      name={"firstName"}
      hasError={meta.touched && !!meta.error}
      errorMessage={meta.error && t(meta.error)}
    >
      {t("checkout:firstName", { defaultValue: "First name" })}
    </Field>
  )
}

const LastName: React.FC = ({}) => {
  const [field, meta, helpers] = useField("lastName")
  const { t } = useTranslation("checkout")

  return (
    <Wrapper>
      <Field
        id="last-name"
        name="lastName"
        as={TextInput}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
      >
        {t("lastName", { defaultValue: "Last name" })}
      </Field>
    </Wrapper>
  )
}

export const AddressLineOne: React.FC<{ fieldName?: string }> = ({
  fieldName = "streetName"
}) => {
  const [field, meta, helpers] = useField(fieldName)
  const { t } = useTranslation("checkout")

  return (
    <Wrapper>
      <Field
        id="address-one"
        name={fieldName}
        as={TextInput}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
      >
        {t("addressLineOne", { defaultValue: "Address Line 1" })}
      </Field>
    </Wrapper>
  )
}

export const AddressLineTwo: React.FC<{ fieldName?: string }> = ({
  fieldName = "additionalStreetInfo"
}) => {
  const [field, meta, helpers] = useField(fieldName)
  const { t } = useTranslation("checkout")

  return (
    <Wrapper>
      <Field
        id="address-two"
        name={fieldName}
        as={TextInput}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
      >
        {t("addressLineTwo", { defaultValue: "Address Line 2" })}
      </Field>
    </Wrapper>
  )
}

export const City: React.FC = ({}) => {
  const [field, meta, helpers] = useField("city")
  const { t } = useTranslation("checkout")
  return (
    <Wrapper>
      <Field
        id="city"
        name="city"
        as={TextInput}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
      >
        {t("city", { defaultValue: "City" })}
      </Field>
    </Wrapper>
  )
}

export const State: React.FC = ({}) => {
  const { t } = useTranslation("checkout")
  const [field, meta, helpers] = useField("state")
  const { usStates } = useAddressReferenceData()

  return (
    <Wrapper>
      <Field
        id="state"
        name="state"
        as={Select}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
        label={t("state")}
      >
        <option disabled value="">
          –
        </option>
        {usStates.map((state, index) => {
          return (
            <option value={state.abbreviation} key={index}>
              {state.name}
            </option>
          )
        })}
      </Field>
    </Wrapper>
  )
}

export const Province: React.FC = ({}) => {
  const [field, meta, helpers] = useField("region")
  const { t } = useTranslation("checkout")
  const { canadianProvinces } = useAddressReferenceData()
  return (
    <Wrapper>
      <Field
        id="region"
        name="region"
        as={Select}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
        label={t("province")}
      >
        <option disabled selected value="">
          –
        </option>
        {canadianProvinces.map((state, index) => {
          return (
            <option value={state.abbreviation} key={index}>
              {state.name}
            </option>
          )
        })}
      </Field>
    </Wrapper>
  )
}

export const Postcode: React.FC = ({}) => {
  const [field, meta, helpers] = useField("postalCode")
  const { t } = useTranslation("checkout")
  return (
    <Wrapper>
      <Field
        id="postcode"
        name="postalCode"
        as={TextInput}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
      >
        {t("postcode", { defaultValue: "Post Code" })}
      </Field>
    </Wrapper>
  )
}

export const IcelandicPostcode: React.FC = ({}) => {
  const { t } = useTranslation("checkout")
  const [field, meta, helpers] = useField("postalCode")
  const { icelandicPostcodes } = useAddressReferenceData()

  return (
    <Wrapper>
      <Field
        id="postcode"
        name="postalCode"
        as={Select}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
        label={t("postcode")}
      >
        <option disabled selected value="">
          –
        </option>
        {icelandicPostcodes.map((postcode, index) => {
          return (
            <option value={postcode} key={index}>
              {postcode}
            </option>
          )
        })}
      </Field>
    </Wrapper>
  )
}

export const Zipcode: React.FC = ({}) => {
  const [field, meta, helpers] = useField("postalCode")
  const { t } = useTranslation("checkout")
  return (
    <Wrapper>
      <Field
        id="postcode"
        name="postalCode"
        as={TextInput}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
      >
        {t("zipcode", { defaultValue: "Zip Code" })}
      </Field>
    </Wrapper>
  )
}

export const Country: React.FC<{
  supportedCountries?: CountryWithCodeI[]
  fieldName?: string
  id?: string
}> = ({
  supportedCountries = defaultSupportedCountries,
  id = "country",
  fieldName = "country"
}) => {
  const { t } = useTranslation("checkout")
  const { t: ft } = useTranslation("footer")
  const [field, meta, helpers] = useField(fieldName)
  return (
    <Wrapper>
      <Field
        id={id}
        name={fieldName}
        as={Select}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
        label={t("country")}
      >
        <option disabled value="">
          –
        </option>
        {supportedCountries.map((country, index) => {
          return (
            <option value={country.code} key={index}>
              {country.code === "IS"
                ? ft(country.code.toLowerCase())
                : country.country.name}
            </option>
          )
        })}
      </Field>
    </Wrapper>
  )
}

export const PhoneNumber: React.FC = ({}) => {
  const [field, meta, helpers] = useField("phone")
  const { t } = useTranslation("checkout")
  return (
    <Wrapper>
      <Field
        id="phone-number"
        name="phone"
        as={PhoneInput}
        hasError={meta.touched && !!meta.error}
        errorMessage={meta.error && t(meta.error)}
      >
        {t("phoneNumberForCourierUpdates", {
          defaultValue: "Phone number for courier updates"
        })}
      </Field>
    </Wrapper>
  )
}
